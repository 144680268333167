body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.float-right{margin-right: 2px;}
/*.not-include{visibility:hidden;height:0;float: left;}*/
.not-include, .hide-title{display: none !important;}
.code-dialog{border: 1px solid #eae8e8 !important;}
.required{color: red;}
.btn-edit{background-color: #e8e8e8 !important;}
.btn-note{padding: .375rem .75rem !important;font-size: 1rem !important;}
.childDiv{ padding: 5px 0px;}
.manage-forms .fa-trash{ float: right;cursor: pointer;color: red;}
.button-section{margin-bottom: 5px;}
.builder-button-section{margin-bottom: 5px;}
.childMain{ padding-right: 0px !important;padding-left: 0px !important;}
.kiosk-mode{height: 0; opacity: 0;}
.error .answer{border: 1px solid #f50606;}
.showStarModalBlue, .showStarModalYellow{margin-top: 70px;z-index: 9999 !important;}
.showStarModalYellow.modal-dialog,.showStarModalBlue.modal-dialog{ box-shadow: 0px 2px 5px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);}
.btn-box-center{text-align: right;}
.check-box input:first-child{ margin-right: 3px; }
.kiosk_check{ margin-right: 2px; }
.pro-text{float: right; margin-top: -20px;margin-right: 140px;}
.progress{width: 65%;margin: auto;}
.btn-box-right {text-align: right;}
.btn-box-right button{margin-left:  5px; }    
.modal-body iframe{ width: 100%; } 
.noti-date{font-size: 15px; display: inline-block; vertical-align: middle;}
.video-section iframe{width: 100%; }
.btn-box-bottom button{ margin-bottom: 5px; }
.select-modal label{ float: left; }
.addNewQuestions{visibility: hidden; height: 0;}
@media only screen and (max-width: 767px) { 
.btn-box{ margin: 15px 0; }
.btn-box-center{text-align: center;} 
}
.CaC{background-color: #FFFF99;padding: 20px;}
.Pat{background-color: #ccf5ff;padding: 20px;}
.PhA, .PmD{background-color: #ececec;padding: 20px;}
.PA, .Int, .Res, .PrA{background-color: #B2FF66;padding: 20px;}
.CPO{background-color: #FFFFFF;padding: 20px;}
.PhT{background-color: #FFCCE5;padding: 20px;}
.commentss{margin-bottom: 20px;}
.image-text{width: 75%;}
.image-text p{margin: 0;}
.float-left{margin-right: 2px;}
.selected{border:solid 2px #24b114;box-shadow:0px 12px 22px 1px #333;}
.slider-td{padding:0 30px;}
.slider-table{margin-left: 15px;}
.pdf-data{visibility: hidden; height: 0;}
.custom-modal{max-width: 890px !important;}
.file-link{ margin-right: 5px; color: #000 !important; background: none !important;}
.col-md-6 iframe{width: 100% !important;}
.answer form {margin: 10px 0px !important;width: 68%;}
.MuiFormControl-root{width: 90%;}
.btn-delete{margin-left: 5px;}
.MuiButton-contained{float: left;margin-right: 10px !important;}
.comments{padding: 5px 10px;margin-bottom: 10px;}
.not-modify{background-color: inherit !important;}
.slide-checkbox{visibility: hidden;}
.not-req{display:none;}
.error{border: 1px solid #f50606 !important;}
.question.error{border: none !important;}
.clear-forms{margin-top: 5px;}
.loader {
  border: 4px solid #ddd;
  border-radius: 50%;
  border-top: 4px solid #ccc;
  border-bottom: 4px solid #ccc;
  width: 20px;
  height: 20px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
  top: 0;
  position: absolute;
  right: 0;
  display:none;
}
.validation{
	display: inline-block;
    margin-right: 20px;
    margin-top: 20px;
	margin-bottom: 10px;
}
.btn-box-center .mr-2 {
    margin-right: .3rem!important;
}
.calculation-div{background-color: #dce6ee !important;}
.cal-res-box{
	text-align: center;
    border: 1px solid;
    padding: 20px;
    margin-left: 15px;
}
.cal-res{
	font-size: 24px;
    margin-bottom: 10px;
}
.subjective .row{margin-bottom: 10px;}
.previewTemp{visibility: hidden;height: 0 !important;}
.auto-tag{color: #00008b;font-weight: bold;}
.req-tag{color: #013220;font-weight: bold;}
.non-req-tag{color: #FF0000;font-weight: bold;}
.auto-tag:after, .req-tag:after, .non-req-tag:after{content: ">>"}
.auto-tag:before, .req-tag:before, .non-req-tag:before{content: "<<"}
.rdw-embedded-modal{height: 200px !important;}
.rdw-editor-wrapper{margin-top: 12px !important;}
.form-filter-a{margin-top: -28px;}
.form-filter-company{margin-bottom: 10px;}
.form-filter-company .form-control{width: 220px;}
.filter-sidebar-4{position:absolute !important; right: 0;}
.pfbN .row{background: #ffebee;}
.makeStyles-root-1 { width: 50%;float: left;}
.api-pdf, .apiPdf-list{margin: 10px 0px;}
.apiPdf-list .fa-file-pdf{color: blue;font-size: 25px;float: right;}
.apiPdf-table{max-height: 220px;overflow: auto;margin-bottom: 10px;background-color: #fff;}
.filter-sidebar{margin-top: -5px;}
.filter-sidebar-2{margin-top: -10px;font-size: 14px;}
.filter-sidebar-scroll{height: 155px;overflow: overlay;}
.col-md-9 .row{margin-bottom: 10px;}
.addNewQuestions-2{height: 0;}
.makeStyles-root-2{width: 75%;padding: 0px 10px;}
.download-csv{text-align: right;margin-right: 60px;margin-top: 45px;}
.download-csv span{margin-right: 15px;}
.csv-down{float: right;margin-right: 80px;cursor: pointer;}
.csv-down-config{float: right;margin-right: 60px;cursor: pointer;}

.add-company{width: 520px !important; margin: auto;}
.popup-btn-com{width: 140px;}
.col-md-company{float: right;}
.filter-sheet option{display:none;}
.filter-sheet option.hasData{display:block !important;}
.manage-facility-id option{display:none;}
.manage-facility-id option.hasData{display:block !important;}
.manage-form li.hide{display:none;}
.add-company-export{margin-left: -22px; margin-top: 30px;}
.select-new-mod{float: left;width: 220px !important; height: 42px !important;}
.sign-out-btn{float: right;width: 120px;}

.view-table .active{background-color: #ceecde;}
.view-table{ height: 200px;overflow: auto;}
.api-icon img{width: 30px; height: 28px;}
.api-icon{ margin-right: 40px;}
.sheet-name-i{width: 60%;float: left;}
.download-icon{width: 38%; float: right;}
.mds-json{margin-right: 12px;}
.config-json{margin-right: 12px;}
.csv-mds-down{margin-right: 80px;}
.download-icon i{cursor: pointer;}
.download-icon .fa-file-text{float: right;margin-right: 8px;}
.mds-description-modal{width: 620px;}
.api-json{float: right;margin-right: 30px;}
.json-list-tr.hide, .json-list-tr.not-cat {display: none;}
.download-api-json {text-align: right;margin-right: 10px;margin-top: 60px;}
.top-mod-select{width: 160px !important; float: left;height: 42px !important;}
.top-mod-select-n{width: 130px !important; float: left;height: 42px !important;margin-right: 10px;}
.fa-download{cursor: pointer;}
.begin-div{ border: 3px solid #ccc;border-bottom: none;}
.end-div, .in-box-last{ border: 3px solid #ccc;border-top: none;}
.in-box{ border-left: 3px solid #ccc;border-right: 3px solid #ccc;padding: 5px 20px;}
.childMain div .in-box:last-child{border-bottom: 3px solid #ccc;}
.begin-div.hide, .end-div.hide, .main-hide{display:none;}
.login-user-top{text-align: right;font-size: 18px;}
.grid-sacale input {width: 133px;float: left; margin-right: 5px; padding: 5px;}
.number-rows{width: 70px !important; margin-bottom: 5px;}
.row-input input{display: inline-block;width: 300px;margin-bottom: 5px;}
.column-input input{display: inline-block;width: 300px;margin-bottom: 5px;}
.grid-type .col-md-2{font-size: 14px;padding: 0px;text-align: center;}
.grid-check{font-size: 14px;padding: 0px 15px;}
.grid-check .form-check{ padding: 5px}
.likert-row{width: 100%;display: grid;}
.weight-val{width: 100px !important;}
.weight-txt{width: 270px !important;}
.order-input{margin-right: 10px;}
.custom-vali{text-align: right;margin-right: 90px;}
.custom-validation{width: 240px !important;display: inline-block !important; margin-left: 10px;}
.basic-grid{font-size: 14px;width: 100%;}
.basic-grid th, .basic-grid td{padding: 5px;}
.output-pre{border: 2px solid #ccc; padding: 10px;}
.edit-header-tile{margin-bottom: 20px;margin-top: 20px;}
.company-select{float: left;width: 200px !important;}
.company-select-1{float: left;width: 265px !important;margin-right: 5px;}
.add-change-tracking{display: flex !important;}
#noneAbove{padding: 15px;}
.grid-dropdown label{display: block;}
.same-for-all{width: 20px !important;}
.dropdown-col3{display:none;}
.select-tags-box{margin-top: 10px; width: 260px;}
.btn-launch{ margin-top: 10px;}
.launch-pdf i{color: #de4040;font-size: 38px;}
.launch-pdf span{font-size: 18px;vertical-align: super;border: 2px solid #cbd2ff;padding: 5px;border-radius: 2px;}
.view-launch-pdf a:hover{text-decoration: none;}
.view-launch-pdf{margin-top: 15px;}

.loader2 {
  border: 4px solid #dddddd;
  border-radius: 50%;
  border-top: 4px solid #3498db;
  width: 30px;
  height: 30px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
  margin-top: 10px;
  margin-left: 70px;
  display:none;
}
.update-users-msg{text-align: center; color: green;font-size: 20px;}
.grid-table-th{width: 75%;}
.activeDiv img {border: 2px solid #108e01;}
.table-bordered{margin-top: 10px;}
.in-image{max-width: 620px;height: auto;}
.showAllSwitch input{visibility: hidden;}
.slider-x-value{text-align: right;font-size: 12px;margin-top: -12px; margin-bottom: -5px;margin-left: 4px;}
/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}























